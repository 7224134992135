@font-face {
    font-family: 'Poppins';
    src: url(./assets/PoppinsExtraLight.ttf) format("truetype");
    font-weight: 1 999;
}

@font-face {
  font-family: 'Poppins Light';
  src: url(./assets/PoppinsLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins Regular';
  src: url(./assets/PoppinsRegular.ttf);
}

@font-face {
    font-family: 'Red Hat Display';
    src: url(./assets/RalewayVariableFontwght.ttf) format("truetype");
    font-weight: 1 999;
}

@font-face {
  font-family: 'Inconsolata';
  src: url(./assets/InconsolataVariableFontwdthwght.ttf) format("truetype");
  font-weight: 1 999;
}

body {
  background-color: #fff;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 300;
  margin-bottom: 75px;
  background-color: #F9CFC0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.navHeader {
  text-shadow: 0.5px 2.8px 3px rgba(0, 109, 119, 0.5);
}

.navLink {
  font-family: 'Poppins Light', sans-serif;
  text-shadow: 0.5px 2.8px 3px rgba(0, 109, 119, 0.5);
}

.sectionHeader {
  text-shadow: 0.5px 2.8px 3px rgba(0, 109, 119, 0.5);
}

.formLabel {
  text-shadow: 0.5px 1px 3px rgba(0, 109, 119, 0.5);
}

.formBtn {
  text-shadow: 0.3px 0.5px 3px rgba(0, 109, 119, 0.3);
}